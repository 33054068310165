<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Uusi lasku</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <invoice-form
      :contr="contract"
      save-btn-text="Luo laskuluonnos"
      @sendinvoice="submit($event)"
    ></invoice-form>
  </div>
</template>

<script>
import InvoiceForm from "../../components/Invoice/InvoiceForm";
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  title: "Luo lasku",
  mixins: [mixins],

  components: {
    InvoiceForm,
  },

  async created() {
    try {
      this.setLoading(true);
      await this.getOne(this.$route.params.id);
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  computed: {
    ...mapState("contract", ["contract"]),
  },

  methods: {
    ...mapActions("contract", ["getOne"]),
    ...mapMutations("invoice", ["setLoading", "setSaving"]),

    async submit(data) {
      try {
        await axiosMethods.post(`/api/v1/invoices/create/${this.$route.params.id}`, data);
        this.setSaving(false);
        this.showPopup("Uusi lasku luotu", "success");
        this.$router.push("/invoices?tab=1");
      } catch (err) {
        this.setSaving(false);
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
